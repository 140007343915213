<template>
  <div>
    <el-table ref="multipleTable" :data="depts">
      <empty slot="empty"></empty>
      <el-table-column label="部门名称" align="center">
        <template slot-scope="scope">
          <department-name :openid="scope.row.id" type="departmentName"></department-name>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" min-width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDept(scope.row)">解绑</el-button>
        </template>
      </el-table-column>


    </el-table>

    <el-pagination @current-change="handleCurrentChange" class="pagination" :current-page="pagination.curPage"
      :page-size="pagination.limit" layout="total, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
  </div>
</template>


<script>
import {
  workDepartmentDeviceUnbind,
  workDepartmentDeviceViewCnt,
  workDepartmentDeviceViews
} from '@/api/work/department';
import DepartmentName from '@/components/DepartmentName';
import { mapGetters } from "vuex";
export default {
  name: '',
  components: {
    DepartmentName
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters(['employee']),
  },
  data: function () {
    return {
      depts: [],
      pagination: {
        deviceUuid: '',
        corpid: '',
        code: '',
        curPage: 0,
        limit: 10,
        total: 0,
      }
    }
  },
  methods: {
    handleCurrentChange(value) {
      this.pagination.corpid = this.employee.corpid
      this.pagination.code = this.employee.code
      this.pagination.curPage = value
      this.pagination.start = (value - 1) * this.pagination.limit
      workDepartmentDeviceViews(this.pagination).then(res => {
        this.depts = res.data || []
      })
    },
    handleDept(data) {
      this.$confirm('取消绑定当前部门不可免费制作饮品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        workDepartmentDeviceUnbind({
          deviceUuid: this.pagination.deviceUuid,
          code: this.employee.code,
          corpid: this.employee.corpid,
          wxId: data.id
        }).then(res => {
          this.loadDept(this.pagination.deviceUuid)
        })
      }).catch(() => {

      })
    },
    loadDept(deviceUuid) {
      let vue = this
      this.pagination.deviceUuid = deviceUuid
      this.pagination.corpid = this.employee.corpid
      this.pagination.code = this.employee.code
      workDepartmentDeviceViews(this.pagination).then(res => {
        vue.depts = res.data || []
      })

      workDepartmentDeviceViewCnt(this.pagination).then(res => {
        vue.pagination.total = parseInt(res.data)
      })


    },
  },
}
</script>
