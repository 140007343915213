<template>
  <div class="device-index">
    <div class="device-department">
      <tree-depart @node-click="handleNodeClick" ref="group"></tree-depart>
    </div>

    <div class="device-list">
      <el-table :data="users">
        <empty slot="empty"></empty>
        <el-table-column label="姓名" align="center" prop="name">
          <template slot-scope="scope">
            <department-name type="userName" :openid="scope.row.wxId"></department-name>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" class="device-edit" @click="handleUserDeviceBind(scope.row.wxId)">绑定设备</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @current-change="handleCurrentChange" class="pagination" :current-page="pagination.curPage"
        :page-size="pagination.limit" layout="total, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>

    </div>


  </div>
</template>

<script>
import {
  workDepartmentEmployeeList,
  workDepartmentEmployeeUnion
} from '@/api/work/department'


import DepartmentName from '@/components/DepartmentName'
import TreeDepart from '@/components/TreeDepart/index.vue'
import { mapGetters } from "vuex"

export default {
  name: '',
  components: {
    TreeDepart,
    DepartmentName
  },
  computed: {
    ...mapGetters(['employee']),
  },
  mounted: function () {
  },

  activated() {

  },
  data: function () {
    return {
      pagination: {
        name: '',
        wxId: 0,
        uuid: '',
        start: 0,
        curPage: 1,
        limit: 10,
        total: 0,
      },
      group: {
        wxId: 1,
        label: '',
      },
      users: [],


    }
  },

  methods: {
    handleNodeClick(node) {
      if (this.pagination.wxId === node.id) {
        return
      }

      console.log(node)
      this.pagination.wxId = node.id
      this.group.wxId = node.id
      this.group.label = node.realName
      this.loadUserSearch()
    },
    loadUserSearch() {
      let corpid = this.employee.corpid
      let code = this.employee.code
      workDepartmentEmployeeUnion({ ...this.pagination, corpid, code }).then(res => {
        this.users = res.data.list || []
        this.pagination.total = parseInt(res.data.total)
      })
    },


    handleCurrentChange(value) {
      this.pagination.curPage = value
      this.pagination.start = (value - 1) * this.pagination.limit
      let corpid = this.employee.corpid
      let code = this.employee.code
      workDepartmentEmployeeList({ ...this.pagination, corpid, code }).then(res => {
        this.users = res.data || []
      })
    },

    handleUserDeviceBind(wxId) {
      this.$emit(`employee-click`, wxId)
    }
  },
}
</script>

<style lang="scss">
.device-index {
  display: flex;

  .remark-circle {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: $_theme_color;
    border-radius: 50%;
    margin-right: 6px;
  }

  .device-department {
    width: 240px;
    padding-right: $padding;
    border-right: 1px solid;
    @include set_border_color($--border-color, $--border-color-dark);

    .device-group {
      width: 100%;
      text-align: center;
      @include set_font_color($_theme_color, $--font-color-dark);
      @include set_bg_color(#eef8ff, #454954);
      cursor: pointer;
      padding: 4px 0;
      border-radius: $border-radius;
      margin-bottom: 4px;
    }

    .device-group:hover {
      font-weight: 500;
    }
  }

  .employee-list {
    padding: $padding;
  }

  .device-list {
    flex: 1;
    padding-left: $padding;

    .device-info {
      font-size: 12px;
      font-weight: 400;
      color: #999;
      margin-left: $padding-4;
    }

    .dept-container {
      border-bottom: 1px solid;
      padding-bottom: 16px;
      @include set_border_color($--border-color, $--border-color-dark);

      &-title {
        font-weight: 500;
        padding-left: $padding;
        display: flex;

        &-name {
          display: inline-block;
          width: 160px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }

      &-radio {
        text-align: right;
      }

      .device-btn-add {
        margin-left: $padding;
      }
    }


    .pagination {
      text-align: right;
      margin-top: $padding-8;

      .el-pagination__total {
        float: left;
      }
    }

    .device-edit,
    .device-detail {
      cursor: pointer;
      display: inline-block;
      margin-right: $padding-8;
      font-size: 13px;
      font-weight: 400;
    }

    .device-edit:hover,
    .device-detail:hover {
      font-weight: 500;
    }

    .device-edit {
      color: $btn_color;
      cursor: pointer;
    }

    .device-detail {
      color: $_theme_color;
      cursor: pointer;
    }

    .circle {
      border-radius: $font_little;
      width: $font_little;
      height: $font_little;
      display: inline-block;
      margin-right: 5px;
    }

    .offline {
      color: #5B8FF9;

      .circle {
        background-color: #5B8FF9;
      }
    }

    .normal {
      color: #5AD8A6;

      .circle {
        background-color: #5AD8A6;
      }
    }

    .warning {
      color: #F6BD16;

      .circle {
        background-color: #F6BD16;
      }
    }

    .fault {
      color: #E8684A;

      .circle {
        background-color: #E8684A;
      }
    }
  }
}
</style>
