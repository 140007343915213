<template>
  <div class="device-index">
    <div class="device-list">
      <el-form label-width="auto" :inline="true" size="mini">
        <el-col :span="6">
          <el-form-item label="地理位置:">
            <el-cascader class="device-input-filter" size="mini" :options="options" :props="{ expandTrigger: 'hover' }"
              @change="handleAreaChange">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备状态:" class="mini">
            <el-select placeholder="请选择设备状态" class="device-input-filter" v-model="pagination.status"
              @change="loadDeviceSearch">
              <el-option v-for="item in deviceStatusOption" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6" class="text-right">
          <el-form-item label="条件搜索:">
            <el-input placeholder="设备名称" class="device-input-filter clear-right-padding" clearable
              v-model="pagination.name" @change="loadDeviceSearch" prefix-icon="el-icon-search"></el-input>
          </el-form-item>

        </el-col>

        <el-col :span="6" class="text-right">
          <el-button type="primary" size="mini" @click="handSyncDevice"><i class="el-icon-refresh"></i> 同步设备</el-button>
          <el-button type="primary" size="mini" @click="handSyncDeviceDrink"><i class="el-icon-refresh"></i> 同步设备饮品
          </el-button>

        </el-col>
      </el-form>
      <el-table ref="multipleTable" :data="devices" @selection-change="handleSelectionChange">
        <empty slot="empty"></empty>
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="uuid" min-width="100"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="name" min-width="120"></el-table-column>
        <el-table-column label="设备状态" align="center">
          <template slot-scope="scope">
            <div class="user-info">
              <span :class="[{ offline: scope.row.status == 1 }, { normal: scope.row.status == 2 },
      { warning: scope.row.status == 3 },
      { fault: scope.row.status == 4 }]">
                <span class="circle"></span> {{ scope.row.status | filterName }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="产品名称" align="center" prop="productName"></el-table-column>
        <el-table-column label="设备Imei" align="center" prop="imei"></el-table-column>

        <el-table-column label="操作" align="center" min-width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDept(scope.row)" style="margin-right: 8px;">可用部门</el-button>
            <el-button type="text" @click="handleProve(scope.row)">直属成员</el-button>
          </template>
        </el-table-column>


      </el-table>

      <div style="display: flex; margin-top: 8px;">
        <el-button type="success" plain @click="toggleProve()" size="mini">绑定成员</el-button>
        <el-button type="success" plain @click="toggleDepart()" size="mini">绑定部门</el-button>
        <el-pagination style="flex: 1;" @current-change="handleCurrentChange" class="pagination"
          :current-page="pagination.curPage" :page-size="pagination.limit" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>

      <c-dialog title="绑定用户" :visible.sync="dialogFormVisible" width="36rem" height="32rem">
        <list-user @employee-click="bindUser"></list-user>
      </c-dialog>

      <c-dialog title="绑定部门" :visible.sync="bindDeptVisible" width="36rem" height="32rem">
        <tree-depart @node-click="handleNodeClick" ref="group"></tree-depart>

        <div slot="title" style="display: flex; justify-content: flex-end;">
          <p style="margin-right: 8px;">当前选择部门: （<department-name :openid="deptWxId"
              type="departmentName"></department-name>）
          </p>
          <el-button size="mini" type="primary" @click="bindDept">绑定部门</el-button>
        </div>


      </c-dialog>

      <c-dialog title="部门列表" :visible.sync="showDeptVisible" width="36rem" height="32rem">
        <depts ref="depts"></Depts>
      </c-dialog>

      <c-dialog title="成员列表" :visible.sync="showProveVisible" width="36rem" height="32rem">
        <prove ref="prove"></prove>
      </c-dialog>




    </div>

  </div>
</template>

<script>
import { deviceList, deviceUnion, equipmentDeviceDrinkSync, equipmentDeviceSync } from '@/api/equipment/device';
import {
  workDepartmentBindDevice,
  workDepartmentDeviceUnbind
} from '@/api/work/department';
import {
  workUserDeviceBind
} from '@/api/work/user';
import DepartmentName from '@/components/DepartmentName';
import CDialog from '@/components/Dialog';
import TreeDepart from '@/components/TreeDepart/index.vue';
import { CodeToText, provinceAndCityDataPlus } from 'element-china-area-data';
import { mapGetters } from "vuex";
import Depts from './depts.vue';
import ListUser from './list_user.vue';
import Prove from './prove.vue';

export default {
  name: '',
  components: {
    ListUser,
    CDialog,
    TreeDepart,
    Depts,
    Prove,
    DepartmentName
  },
  mounted: function () {
    this.loadDevice()
  },
  computed: {
    ...mapGetters(['employee']),
  },
  data: function () {
    return {
      options: provinceAndCityDataPlus,
      bindDeptVisible: false,
      dialogFormVisible: false,
      showDeptVisible: false,
      showProveVisible: false,
      devices: [],
      deviceStatusOption: [
        { label: '所有设备', value: 0 },
        { label: '离线', value: 1 },
        { label: '在线', value: 2 },
      ],
      pagination: {
        name: '',
        dutyName: '',
        status: 0,
        province: '',
        city: '',
        start: 0,
        curPage: 1,
        limit: 10,
        total: 0,
      },
      device: {
        deviceInfo: '',
        dutyName: '',
        province: '',
        city: '',
        address: '',
        lat: '',
        lng: '',
        block: false,

      },
      groups: [],
      deviceId: [],
      multipleSelection: [],
      deviceShow: false,
      deviceDrinkShow: false,
      deptWxId: 0,
      deviceIndex: 0,
    }
  },
  filters: {
    filterName(status) {
      switch (status) {
        case 1:
          return '离线'
        case 2:
          return '正常'
        case 3:
          return '待维护'
        case 4:
          return '待维修'
      }
    },
  },
  methods: {
    handleDept(data) {
      this.showDeptVisible = true
      this.$nextTick(() => {
        this.$refs.depts.loadDept(data.uuid)
      })
    },

    handleProve(data) {
      this.showProveVisible = true
      this.$nextTick(() => {
        this.$refs.prove.loadProve(data.uuid)
      })
    },

    handleSelectionChange(val) {
      let deviceId = []
      val.forEach((item) => {
        deviceId.push(item.uuid)
      })
      this.deviceId = deviceId
    },
    toggleDepart() {
      if (this.deviceId.length <= 0) {
        this.$message.error('请选取对应设备')
        return
      }
      this.bindDeptVisible = true
    },
    toggleProve() {
      if (this.deviceId.length <= 0) {
        this.$message.error('请选取对应设备')
        return
      }
      this.dialogFormVisible = true
    },

    handleDeviceUnbind(uuid, index) {
      this.$confirm('取消绑定当前部门不可免费制作饮品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        workDepartmentDeviceUnbind({
          deviceUuid: uuid,
          wxId: this.group.wxId,
          corpid: this.employee.corpid,
          code: this.employee.code,
        }).then(res => {
          this.devices.splice(index, 1)
        })
      }).catch(() => {

      })
    },

    handleNodeClick(node) {
      this.deptWxId = node.id
    },

    bindUser() {
      workUserDeviceBind({
        wxId: this.deptWxId,
        code: this.employee.code,
        corpid: this.employee.corpid,
        deviceUuid: this.deviceId
      }).then(res => {
        this.dialogFormVisible = false
        this.$message.success('绑定成功')
      })
    },

    bindDept(wxId) {
      workDepartmentBindDevice({
        corpid: this.employee.corpid,
        code: this.employee.code,
        wxId: wxId,
        deviceUuid: this.deviceId,
      }).then(res => {
        this.bindDeptVisible = false
        this.$message.success('绑定成功')
      })
    },

    handSyncDevice() {
      equipmentDeviceSync().then(res => {
        this.$message.success('同步成功')
        this.loadDevice()
      })
    },

    handSyncDeviceDrink() {
      equipmentDeviceDrinkSync().then(res => {
        this.$message.success('同步成功')
      })
    },

    handleAreaChange(value) {
      this.pagination.city = ''
      if (!value[0].length) {
        this.pagination.province = ''
        this.pagination.city = ''
      } else {
        this.pagination.province = CodeToText[value[0]]
        if (value[1].length) {
          let city = CodeToText[value[1]]
          if (city !== '全部') {
            this.pagination.city = city
          }
        }
      }
      this.loadDeviceSearch()
    },

    handleCurrentChange(value) {
      this.pagination.curPage = value
      this.pagination.start = (value - 1) * this.pagination.limit
      deviceList(this.pagination).then(res => {
        this.devices = res.data || []
      })
    },
    loadDevice() {
      let vue = this
      deviceUnion(this.pagination).then(res => {
        vue.devices = res.data.list || []
        vue.pagination.total = parseInt(res.data.total)
      })
    },
    loadDeviceSearch() {
      let vue = this
      this.pagination.curPage = 1
      this.pagination.start = 0
      deviceUnion(this.pagination).then(res => {
        vue.devices = res.data.list || []
        vue.pagination.total = parseInt(res.data.total)
      })
    },



    handleDeviceUpdate() {
      if (this.device.name === ``) {
        this.$message.error('请完善设备名称')
        return
      }

      if (this.device.province === ``) {
        this.$message.error('请设定设备所在位置')
        return
      }

      let vue = this
      this.device.lat = this.device.lat + ''
      this.device.lng = this.device.lng + ''
      this.device.lock = this.device.block ? 1 : 0

      devicePositionUpdate(this.device).then(() => {
        let device = vue.devices[vue.deviceIndex]
        device.name = vue.device.name
        device.province = vue.device.province
        device.city = vue.device.city
        device.address = vue.device.address
        vue.updateDeviceVisible = false
        vue.devices[vue.deviceIndex].lock = vue.device.lock
        console.log(vue.devices[vue.deviceIndex].block)
        vue.$message.success('更新成功')
      })
    },
  },
}
</script>

<style lang="scss">
.device-index {
  display: flex;

  .device-list {
    flex: 1;
    padding-left: $padding;

    @media (max-width: 1366px) {
      .el-form-item__label {
        width: 64px;
      }
    }

    @media (max-width: 1442px) {
      .el-form-item__label {
        width: 72px;
      }
    }

    .el-form-item__content {
      width: 150px;

      .clear-right-padding {
        .el-input__inner {
          padding-right: 8px;
        }

      }
    }

    .device-info {
      .device-info-img {}

      .device-info-detail {

        p {
          margin-bottom: $padding;
        }

        p:first-child {
          font-size: .8rem;
        }
      }
    }

    .text-right {
      text-align: right;
    }

    .el-table {
      thead {
        tr {
          th {
            border-bottom: 1px solid;
            @include set_border_color($--border-color, $--border-color-dark);
          }
        }
      }

      .cell {
        font-size: $font_little;
      }

    }

    .user-info {
      p:first-child {
        margin-bottom: $padding;
      }
    }

    .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      background-color: transparent;
    }

    .el-table__row:hover {
      @include set_bg_color($background-color-light, $background-color-dark);
    }

    .pagination {
      text-align: right;
      margin-top: $padding-8;

      .el-pagination__total {
        float: left;
      }
    }

    .device-edit,
    .device-detail {
      cursor: pointer;
      display: inline-block;
      margin-right: $padding-8;
    }

    .device-edit:hover,
    .device-detail:hover {
      font-weight: 500;
    }

    .device-edit {
      color: $btn_color;
      cursor: pointer;
    }

    .device-detail {
      color: $_theme_color;
      cursor: pointer;
    }

    .circle {
      border-radius: $font_little;
      width: $font_little;
      height: $font_little;
      display: inline-block;
      margin-right: 5px;
    }

    .offline {
      color: #5B8FF9;

      .circle {
        background-color: #5B8FF9;
      }
    }

    .normal {
      color: #5AD8A6;

      .circle {
        background-color: #5AD8A6;
      }
    }

    .warning {
      color: #F6BD16;

      .circle {
        background-color: #F6BD16;
      }
    }

    .fault {
      color: #E8684A;

      .circle {
        background-color: #E8684A;
      }
    }
  }
}
</style>
