<template>
  <div>
    <el-table ref="multipleTable" :data="proves">
      <empty slot="empty"></empty>
      <el-table-column label="员工名称" align="center">
        <template slot-scope="scope">
          <department-name :openid="scope.row.id" type="userName"></department-name>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" min-width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDept(scope.row)">解绑</el-button>
        </template>
      </el-table-column>


    </el-table>
    <el-pagination @current-change="handleCurrentChange" class="pagination" :current-page="pagination.curPage"
      :page-size="pagination.limit" layout="total, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
  </div>

</template>


<script>
import {
  workUserDeviceUnbind,
  workUserDeviceViewCnt,
  workUserDeviceViews,
} from '@/api/work/user.js';
import DepartmentName from '@/components/DepartmentName';
import { mapGetters } from "vuex";
export default {
  name: '',
  components: {
    DepartmentName
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters(['employee']),
  },
  data: function () {
    return {
      proves: [],
      pagination: {
        deviceUuid: '',
        corpid: '',
        code: '',
        curPage: 0,
        limit: 10,
        total: 0,
      }
    }
  },
  methods: {
    handleCurrentChange(value) {
      this.pagination.corpid = this.employee.corpid
      this.pagination.code = this.employee.code
      this.pagination.curPage = value
      this.pagination.start = (value - 1) * this.pagination.limit
      workUserDeviceViews(this.pagination).then(res => {
        this.proves = res.data || []
      })
    },
    handleDept(data) {
      this.$confirm('操作仅解绑用户直接使用权限，如果所在部门还有使用权限，则可以继续使用设备', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        workUserDeviceUnbind({
          deviceUuid: this.pagination.deviceUuid,
          code: this.employee.code,
          corpid: this.employee.corpid,
          wxId: data.id
        }).then(res => {
          this.loadDept(this.pagination.deviceUuid)
        })
      }).catch(() => {

      })
    },
    loadDept(deviceUuid) {
      let vue = this
      this.pagination.deviceUuid = deviceUuid
      this.pagination.corpid = this.employee.corpid
      this.pagination.code = this.employee.code
      workUserDeviceViews(this.pagination).then(res => {
        vue.proves = res.data || []
      })

      workUserDeviceViewCnt(this.pagination).then(res => {
        vue.pagination.total = parseInt(res.data)
      })
    },
  },
}
</script>
